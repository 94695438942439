.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-grids/styles/material.css';
@import '../node_modules/@syncfusion/ej2-react-spreadsheet/styles/material.css';


/*
Syncfusion Datagrid
Primary: #788e1b
*/
.e-grid .e-sortfilterdiv{
    margin:-16px 10px !important;
    padding:3px !important;
}

.e-pager .e-currentitem,
.e-pager .e-currentitem:hover{
    background-color: #788e1b !important;
}


/* 

CUSTOM SCROLLBAR 

*/
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  border: 0px solid transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #666;
  }

*:hover::-webkit-scrollbar-thumb  {
    background-color: #666;
    border:none;
  }

  *:hover::-webkit-scrollbar-thumb:vertical  {
    background-color: #666;
    border:none;
  }
  

/* VERTICAL */
*::-webkit-scrollbar:vertical,
*::-webkit-scrollbar-thumb:vertical {
    width: 10px;
}

*::-webkit-scrollbar-thumb:vertical {
    width: 10px;
    border-right: 2px solid #666;
}

*:hover::-webkit-scrollbar:vertical {
    width: 10px;
}

*:hover::-webkit-scrollbar-thumb:vertical  {
    width: 10px;
}
/* HORIZONTAL */
*::-webkit-scrollbar:horizontal,
*::-webkit-scrollbar-thumb:horizontal {
    height: 10px;
}

*::-webkit-scrollbar-thumb:horizontal {
    height: 10px;
    border-bottom: 2px solid #666;
}

*:hover::-webkit-scrollbar:horizontal {
    height: 10px;
}

*:hover::-webkit-scrollbar-thumb:horizontal  {
    height: 10px;
}



/* 

HTML

*/


html {
    scroll-behavior: smooth;
  }


html, body, #root, #bb_studio_app, #content-wrapper, #content{
	height: 100%;
}

#content-wrapper{
	background-color: #f8f9fc;
}

.hero{
    min-height: auto;
}

/*
.logo {
    background: url(/themes/ilias/img/logo/Ilias_RGB.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    min-width: 10rem;
    min-height: 3rem;
}

.logo.white{
	background: url(/themes/ilias/img/logo/Ilias_White.png);
	background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}
*/
.shadow{
	box-shadow: 0 .15rem .75rem 0 rgba(58,59,69,.15)!important;
}

.container,
.container-fluid{
	position: relative;
	/*height: 100%;*/
}

.viewport{
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-x: hidden;
	overflow-y: auto;
}

.list{
	overflow-y: auto;
}


main{
	overflow: auto;
}
  


.cursor-alias {cursor: alias;}
.cursor-all-scroll {cursor: all-scroll;}
.cursor-auto {cursor: auto;}
.cursor-cell {cursor: cell;}
.cursor-context-menu {cursor: context-menu;}
.cursor-col-resize {cursor: col-resize;}
.cursor-copy {cursor: copy;}
.cursor-crosshair {cursor: crosshair;}
.cursor-default {cursor: default;}
.cursor-e-resize {cursor: e-resize;}
.cursor-ew-resize {cursor: ew-resize;}
.cursor-grab {cursor: -webkit-grab; cursor: grab;}
.cursor-grabbing {cursor: -webkit-grabbing; cursor: grabbing;}
.cursor-help {cursor: help;}
.cursor-move {cursor: move;}
.cursor-n-resize {cursor: n-resize;}
.cursor-ne-resize {cursor: ne-resize;}
.cursor-nesw-resize {cursor: nesw-resize;}
.cursor-ns-resize {cursor: ns-resize;}
.cursor-nw-resize {cursor: nw-resize;}
.cursor-nwse-resize {cursor: nwse-resize;}
.cursor-no-drop {cursor: no-drop;}
.cursor-none {cursor: none;}
.cursor-not-allowed {cursor: not-allowed;}
.cursor-pointer {cursor: pointer;}
.cursor-progress {cursor: progress;}
.cursor-row-resize {cursor: row-resize;}
.cursor-s-resize {cursor: s-resize;}
.cursor-se-resize {cursor: se-resize;}
.cursor-sw-resize {cursor: sw-resize;}
.cursor-text {cursor: text;}
.cursor-w-resize {cursor: w-resize;}
.cursor-wait {cursor: wait;}
.cursor-zoom-in {cursor: zoom-in;}
.cursor-zoom-out {cursor: zoom-out;}


/*
.navbar{
	font-size: 1.1em;
}
.navbar .nav-item{
	transition: background-color 0.2s ease-in-out;
	border-radius: 8px;
	padding-left:10px;
	padding-right:10px;
}
.navbar .nav-item:hover:not(.disabled){
	background-color: #efefef;
}

.navbar .nav-item.disabled a{
	opacity: 0.5;
	cursor: not-allowed;
}

.navbar img{
	max-height: 32px;
}
.navbar .right{
	position: absolute;
	right: 10px;
	display: flex;
}
.navbar .dropdown-menu{
	z-index: 2000;
}

*/
#page-header .dropdown-toggle::after {
    display: none !important;
}


.split .gutter.gutter-horizontal{
    cursor: col-resize;
    background-color: #ddd;
}

.sorthandle.vertical{
    cursor: row-resize;
}

/* React DataTable */
.QSgaM:hover {
    background-color:#f6f7f9 !important;
}
.doqUdF{
    height: inherit!important;
}
.cbUDym {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
.rdt_TableCell,
.rdt_TableCol{
    padding: 0 !important;
}

.rdt_TableCell div:first-child {
    /*white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;*/
    white-space: inherit!important; 
    overflow: inherit!important; 
    text-overflow: inherit!important;
}

.rdt_Table .react-datepicker-wrapper,
.rdt_Table .react-datepicker__input-container{
    height: 100%!important;
}

.rdt_Table .react-datepicker-popper{
    min-width: 300px;
}

.react-datepicker-popper{
    z-index: 2000 !important; /* 23/06/23*/
    /*z-index: 2 !important;*/
}

.rdt_Table .combo_in_table > div:first-child{
    border: none!important;
    background:none;
}

.checkbox  .rdt_Table .rdt_TableCell:first-child,
.checkbox  .rdt_Table .rdt_TableCol:first-child{
    max-width: 32px;
}

/* Dropdown column */
.rdt_Table .fHSQvF,
.rdt_Table .jSPoll {
    min-width: 3rem;
    max-width: 3rem;
}


.rdt_Pagination{
    justify-content: start !important;
    -webkit-justify-content: start !important;
}


.list-group-item.selected::after{
    content:" ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    background-color: #333;
}

.dropdown-item {
    padding:0.25rem;
    margin:0;
}

i.history-item{
    font-size: 2rem !important;
    margin-top: 0.25rem !important;
}

.shadow-inset{
    box-shadow: inset 0 .15rem .75rem 0 rgba(58,59,69,.15)!important;
}

.overflow-y-auto{
    overflow-y: auto;
}

.overflow-x-auto{
    overflow-x: auto;
}

.overflow-x-hidden{
    overflow-x: hidden;
}

.overflow-y-hidden{
    overflow-y: hidden;
}


.no-carat::after {
    content: none !important;
}


#page-container.page-header-fixed #main-container{
    height: 100%;
    padding: 0 !important;
    margin-top: 68px;
    box-shadow: inset 4px 4px 20px #00000012;
}

#page-header .content-header{
    max-width: inherit !important;
}

.compact .form-group{
    margin-bottom: 0.125rem;
}

.compact .form-group label{
    margin-bottom: 0.125rem;
}

optgroup{
    background-color: #f6f7f9;
}

option{
    background-color: #fff;
}

.custom-checkbox label.custom-control-label{
    margin-left: 1.5rem;;
}

.react-slidedown {
    transition-duration: .2s !important;
    transition-timing-function: ease-in-out;
}

.react-datepicker-wrapper{
    display:block;
}



.searchinput-wrapper{
    width: 100%;
    position: relative;
}

.searchinput-wrapper input{
    width: 100%;
    height: 2rem;
    border-radius: 2rem;
}


.searchinput-wrapper i{
    position: absolute;
    right:0.75rem;
    top:0.75rem;
}



.block.block-mode-loading::before{
    background-color: #ffffff79;
}

.block.block-mode-loading>.block-content, .block.block-mode-loading>.block-header, .block.block-mode-loading>.nav-tabs {
    opacity: 1 !important;
}

.loading{
    position: relative;
    overflow: hidden;
    min-height: 7rem;
}

.loading::before{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    z-index: 1;
    content: " ";
    background-color: #ffffff79;
}

.loading::after{
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25px 0 0 -25px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: #343a40;
    font-family: Simple-Line-Icons;
    font-size: 18px;
    text-align: center;
    z-index: 2;
    content: '\E09A';
    -webkit-animation: fa-spin 1.75s infinite linear;
    animation: fa-spin 1.75s infinite linear;
    border-radius: 25px;
    box-shadow: 0 0 15px 15px rgba(52,58,64,.05);
}


.form-item-icon{
    position: absolute;
    right: 0.75rem;
    top: 0.5rem;
    bottom: 0;
    font-size: 150%;
    pointer-events: none;
}




.dropdown .dropdown-menu {
    -webkit-transition: opacity .3s !important;
    transition: opacity .3s !important;
}

.opacity-0 {
    opacity:0!important;
}
.opacity-1 {
    opacity:0.2!important;
}
.opacity-2 {
    opacity:0.4!important;
}
.opacity-3{
    opacity:0.6!important;
}
.opacity-4 {
    opacity:.8!important;
}
.opacity-5 {
    opacity:1!important;
}

.reveal [hidden] {
    display: block  !important;
}


/* 

BRANDING

*/

.bg-light-10 { background-color: rgba(0,87,121,0.1) !important;}
[data-theme="dark"] .bg-light-10 { background-color: rgba(0,87,121,0.1) !important; }
[data-theme="dark"] .menu-header .search:focus-within .search-input{background-color: rgb(0, 0, 0);}


.react-slidedown {
    overflow: visible !important;
}
.react-slidedown.transitioning {
    overflow-y: visible !important;
}
